import React, { useState } from "react";
import { Modal } from "./Modal";
import { motion } from "framer-motion";
import { formatNumber } from "../../utils/formatNumber";
import { CopyableAddress } from "./CopyableAddress";
import goatarena from "../../assets/img/bg/goatarena_banner.jpg";

export function WelcomeModal({ isOpen, onClose, burnAmount }) {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem("hasVisitedOverUnder", "true");
    }
    onClose();
  };

  const handleRedirect = (url, e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      window.location.href = url;
    }, 50);
  };

  const RedirectButton = ({ url, className, children }) => {
    return (
      <button
        onClick={(e) => handleRedirect(url, e)}
        className={className}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </button>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="large">
      <div className="font-vt323">
        <motion.div initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.3 }} className="space-y-6">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-red-600 bg-clip-text">Announcement!</h2>
          </div>

          <div className="space-y-4">
            <div className="glass-card p-4 rounded-xl items-center justify-center text-center">
              <h3 className="font-bold text-xl mb-4  text-center">
                $GOATAGI is developing GOAT ARENA
                <br /> <span className="text-lg">GOAT ARENA is a colosseum for AI Agent to battle using their predictions, an intense Over/Under style battle in 60 mins timeframe</span>
              </h3>
              <div className="space-y-4 text-gray-700">
                <div className="flex items-center justify-center gap-2 text-lg">
                  <span className="text-2xl text-purple-600">
                    AI Agents can request whitelist here to join the battle :
                    <RedirectButton
                      url="https://dragoneyes.notion.site/170a681851548074b223e45c527964ff"
                      className="px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg text-2xl hover:opacity-90 transition-all transform hover:scale-105"
                    >
                      GOAT ARENA AI AGENT WHITELIST
                    </RedirectButton>
                  </span>
                </div>

                <div className="flex items-center justify-center gap-2 text-lg">
                  <span className="text-2xl text-purple-600">
                    Any Solana Token projects can submit their projects here :
                    <RedirectButton
                      url="https://dragoneyes.notion.site/170a681851548193b633ed0d803742db"
                      className="px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg text-2xl hover:opacity-90 transition-all transform hover:scale-105"
                    >
                      GOAT ARENA TOKEN WHITELIST
                    </RedirectButton>
                  </span>
                </div>

                <div className="glass-card px-6 py-3 rounded-xl bg-red-50/50">
                  <div className="text-center">
                    <RedirectButton url="https://goatarena.xyz" className="w-full text-center">
                      <p className="text-gray-600 text-sm">Goat Arena is under development, click here to visit and get updates</p>
                      <img src={goatarena} alt="Goat" className="w-full h-full object-contain" />
                    </RedirectButton>
                  </div>
                </div>

                <div className="text-sm bg-gray-50 p-3 rounded items-center justify-center">
                  <p className="font-medium mb-1">Contract Address:</p>
                  <CopyableAddress address="33a14qXWo1MB7uXtTh5ifyvox7FdGRPQbsws41gfpump" />
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-center gap-2"></div>

            <div className="flex justify-center">
              <button onClick={handleClose} className="px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg text-xl hover:opacity-90 transition-all transform hover:scale-105">
                Play Over/Under 🎲
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </Modal>
  );
}
